import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import RegisterLayout from "../../layout/RegisterLayout";
import style from "../../styles/register/Resiger.scss";

const RegisterConfirm = () => {
  const history = useHistory()

  const toConfirm = () => {
    history.push('/login')
  }

  return (
    <>
      <RegisterLayout>
        <div className={style.container}>
          <div className={style.title}>
            ご登録確認
          </div>
          <table className={style.table}>
            <tr>
              <th>ID</th>
              <td>abcd@cardandmedia.com</td>
            </tr>
            <tr>
              <th>パス</th>
              <td>XXXXXXXX</td>
            </tr>
            <tr>
              <th>姓名</th>
              <td>名刺太郎</td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td>2000/1/3</td>
            </tr>
            <tr>
              <th>メール</th>
              <td>plus@cardandmedia.com</td>
            </tr>
          </table>
          <Button onClick={toConfirm}>確認</Button>
        </div>
      </RegisterLayout>
    </>
  )
}

export default RegisterConfirm;
