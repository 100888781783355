import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import RegisterLayout from "../../layout/RegisterLayout";
import style from '../../styles/register/Resiger.scss'

const Register = () => {
  const history = useHistory()

  const toConfirm = () => {
    history.push('/register/confirm')
  }

  return (
    <>
      <RegisterLayout>
        <div className={style.container}>
          <div className={style.title}>
            ご登録
          </div>
          <table className={style.table}>
            <tr>
              <th>ID</th>
              <td><input /></td>
            </tr>
            <tr>
              <th>パス</th>
              <td><input /></td>
            </tr>
            <tr>
              <th>姓名</th>
              <td><input /></td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td><input /></td>
            </tr>
            <tr>
              <th>メール</th>
              <td><input /></td>
            </tr>
          </table>
          <Button className={style.button} onClick={toConfirm}>確認</Button>
        </div>
      </RegisterLayout>
    </>
  )
}

export default Register;
