import React, {useState} from "react";
import style from './Login.scss';
import Layout from "../layout/Layout";
import {login} from "../util/authentication";
import {useHistory} from "react-router";

const Login = () => {
  const history = useHistory()
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async (e) => {
    const successLogin = await login({userId, password})
    if (successLogin) {
      history.push('/')
    }
  }

  const toRegister = () => {
    history.push('/register/propose')
  }
  const toResetPassword = () => {
    history.push('/password')
  }

  const canLogin = () => {
    return userId !== '' && password !== ''
  }

  return (
    <div className="App">
      <Layout category={''}>
        <div className={style.loginTop}>
          <div className={style.contents}>
            <div>
              <div className={style.mainTitle}>
              </div>
              <div className={style.loginForm}>
                <div className={style.loginTitleLine}>
                  <span className={style.loginTitle}>メールアドレス</span>
                  <input type="text" onChange={(e) => setUserId(e.target.value)}/>
                </div>
                <div className={style.loginTitleLine}>
                  <span className={style.loginTitle}>パスワード</span>
                  <input type="password" onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <div>
                  <button onClick={handleLogin} className={ canLogin() ? style.loginButton : style.disableButton }>ログイン</button>
                </div>
              </div>
              <div className={style.passwordInformation}>
                ご利用登録は
                <span onClick={toRegister} className={style.link}>コチラ</span>
              </div>
              <div className={style.passwordInformation}>
                パスワードの初回登録と、パスワードを忘れた方は
                <span onClick={toResetPassword} className={style.link}>コチラ</span>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Login;
