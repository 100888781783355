import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import RegisterLayout from "../../layout/RegisterLayout";
import style from '../../styles/register/Resiger.scss'

const Apply = () => {
  const history = useHistory()

  const toRegister = () => {
    history.push('/register/register')
  }

  return (
    <>
      <RegisterLayout>
        <div className={style.container}>
          <div className={style.title}>
            アプリのご利用規約
          </div>
          <div>
            ○○○○○○○○○○○○○○○○○○○○○○○○○
          </div>
          <Button className={style.button} onClick={toRegister}>同意して利用開始</Button>
        </div>
      </RegisterLayout>
    </>
  )
}

export default Apply;
