import React from "react";
import globalStyle from '../styles/Global.scss'
import Layout from "../layout/Layout";
import {useHistory} from "react-router-dom";

const Top = () => {
  const history = useHistory()

  const toWatashiDetail = (id) => {
    history.push(`/watashi/${id}`)
  }

  return (
    <Layout title={'登録名刺'}>
      <>
        <div className={globalStyle.container}>
          <div>
            <img onClick={() => toWatashiDetail(1)} alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          </div>
          <div>
            <img onClick={() => toWatashiDetail(2)} alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          </div>
          <div>
            <img onClick={() => toWatashiDetail(3)} alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default Top;
