import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import RegisterLayout from "../../layout/RegisterLayout";
import style from '../../styles/register/Resiger.scss'

const Propose = () => {
  const history = useHistory()

  const toApply = () => {
    history.push('/register/apply')
  }

  return (
    <>
      <RegisterLayout>
        <div className={style.container}>
          <div className={style.title}>
            Card Plus
          </div>
          <div>
            名刺を渡した人に、年間の営業日をお伝えできます。業務内容のメニュー、その他情報をテキスト、画像でスマホに反映、その進捗内容をすでにお渡しした全員に確認していただけます。
          </div>
          <div>
            名刺の受取のみに使用する場合は料金がかかりませんが、名刺を渡すには名刺の登録が必要、名刺1点の登録で500円が必要です。ユーザー数50名まで無料、51〜500名ま500円/月、501名〜700円/月必要です。
          </div>
          <Button className={style.button} onClick={toApply}>スタート</Button>
        </div>
      </RegisterLayout>
    </>
  )
}

export default Propose;
