import globalStyle from '../../styles/Global.scss';
import style from './Watashi.scss'
import React from "react";
import Layout from "../../layout/Layout";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const WatashiCardEdit = () => {
  const history = useHistory()

  const toBack = () => {
    history.goBack()
  }
  const save = () => {
    history.goBack()
  }


  return (
    <>
      <Layout category={'WA'}>
        <div className={globalStyle.container}>
          <img alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          <div className={style.dataContainer}>
            <table className={style.table}>
              <tr>
                <th width={'100px'}>
                  <input value={'会社名/店名'}/>
                </th>
                <td width={'200px'}>
                  <input value={'○○○○○○○○'}/>
                </td>
              </tr>
              <tr>
                <th>
                  <input value={'所属'}/>
                </th>
                <td width={'200px'}>
                  <input value={'○○○○○○○○'}/>
                </td>
              </tr>
              <tr>
                <th>
                  <input value={'姓名'}/>
                </th>
                <td width={'200px'}>
                  <input value={'○○○○○○○○'}/>
                </td>
              </tr>
              <tr>
                <th>
                  <input value={'〒'}/>
                </th>
                <td width={'200px'}>
                  <input value={'○○○○○○○○'}/>
                </td>
              </tr>
              <tr>
                <th>
                  <input value={'住所'}/>
                </th>
                <td width={'200px'}>
                  <input value={'○○○○○○○○'}/>
                </td>
              </tr>
              <tr>
                <th>
                  <input value={'TEL/FAX'}/>
                </th>
                <td width={'200px'}>
                  <input value={'○○○○○○○○'}/>
                </td>
              </tr>
            </table>
            <div className={globalStyle.footerContainer}>
              <div className={globalStyle.footer}>
                <div className={globalStyle.centerPanel}>
                  <Button className={style.button} onClick={() => {save()}}>保存</Button>
                  <Button className={style.button} onClick={() => {toBack()}}>キャンセル</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default WatashiCardEdit
