import React from 'react'
import {Nav, Navbar, NavItem} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import style from './Layout.scss'

const RegisterLayout = ({children}) => {
  return (
    <>
      <Navbar className={`me-auto ${style.topBar}`}>
        <Nav className="me-auto">
          <NavItem className={style.brand}>名刺プラス</NavItem>
        </Nav>
        <Nav className="justify-content-end">
        </Nav>
      </Navbar>
      <div className="contents">
        <section className={style.pageContainer}>
          <main>{children}</main>
        </section>
      </div>
    </>
  )
}

export default RegisterLayout
