import {apiBase} from '../api/ApiBase'
import {auth, me} from '../api/index'
import {getFromStorage, removeFromStorage, storeToStorage} from '../store/storage'

const tokenKey = 'token'

export const login = async ({userId, password}) => {
  const token = await auth({userId, password})
  if (!token) {
    return false
  }
  storeToStorage(tokenKey, token)
  apiBase.token = token

  return true
}

export const logout = () => {
  removeFromStorage(tokenKey)
  apiBase.token = null
}

export const getToken = () => {
  return getFromStorage(tokenKey, null)
}

export const restoreToken = () => {
  apiBase.token = getFromStorage(tokenKey, null)
}

export const isLogin = async () => {
  const token = getToken()
  if (!token) {
    return false
  }
  try {
    apiBase.token = token
    await me()
  } catch (_) {
    logout()
    return false
  }
  return true
}
